$(document).ready(function() {
    $('.photo').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    $("a.modal-btn").click(function() {
        $('#'+$(this).attr('data-target')).addClass('active');
        $('body').addClass('locked');
    });

    $(".modal .close").click(function() {
        $(this).closest('.modal').removeClass('active');
        $('body').removeClass('locked');
    });

    $("a.menu").click(function() {
        if($('#head').hasClass('active')){
            $('#head').removeClass('active');
            $(this).removeClass('active');
            $(this).find('i.icon').addClass('icon-menu').removeClass('icon-close');
            $('body').removeClass('locked');
        }else{
            $('body').addClass('locked');
            $('#head').addClass('active');
            $(this).addClass('active');
            $(this).find('i.icon').removeClass('icon-menu').addClass('icon-close');
        }
    });

    $('#head li a').click(function() {
        if($('#head').hasClass('active')){
            $('#head').removeClass('active');
            $('#head').find('i.icon').addClass('icon-menu').removeClass('icon-close');
            $('body').removeClass('locked');
        }
    });

    $('input').iCheck({
      checkboxClass: 'icheckbox_square-blue',
      radioClass: 'iradio_square-blue',
      increaseArea: '20%' // optional
    });

    $('.form-group .choice-cup').click(function(){
        obj  = $(this);

        if(obj.hasClass('checked')){
            $(this).find('img').attr('src',$(this).find('img').attr('src').replace( /-checked/g,''));
            $( this ).removeClass( "checked" );
            obj.find('input').attr('disabled','disabled');
        }else{
            img = obj.find('img');
            img.attr('src',img.attr('src').replace( /.jpg/g,'-checked.jpg'));
            obj.addClass('checked');
            obj.find('input').removeAttr('disabled');
        }

        bool = false;
        $( ".form-group .choice-cup.checked" ).each(function() {
            $('.form-group input[name="typ"]').attr('value',1);
            bool = true;
        });

        if(!bool){
            $('.form-group input[name="typ"]').attr('value','');
        }

    });

    $('.form-group .choice-cup-size').click(function(){
        obj  = $(this);
        /*
        */
        if(obj.hasClass('checked')){
            obj.removeClass( "checked" );
            obj.find('input').attr('disabled','disabled');
        }else{
            obj.find('input').removeAttr('disabled');
            obj.addClass('checked');
        }
        bool = false;
        $( ".form-group .choice-cup-size.checked" ).each(function() {
            $('.form-group input[name="velikost"]').attr('value',1);
            bool = true;
        });

        if(!bool){
            $('.form-group input[name="velikost"]').attr('value','');
        }

    });


    $.fn.randomize = function(selector){
          var $elems = selector ? $(this).find(selector) : $(this).children(),
          $parents = $elems.parent();

          $parents.each(function(){
            $(this).children(selector).sort(function(){
                return Math.round(Math.random()) - 0.5;
            }).detach().appendTo(this);
          });

          return this;
    };

    var owlArticle = $('.owl-article');


    owlArticle.owlCarousel({
        loop: true,
        margin: 0,
        nav: true,
        dots: false,
        navText: ['<i class="icon icon-arrow-left"><i>','<i class="icon icon-arrow-right"><i>'],
        items: 4,
        autoplay:false,
        responsive : {
            0 : {
                items: 1
            },
            480 : {
                items: 2
            },
            768 : {
                items: 3
            },
            1000 : {
                items: 4
            }
        },
        onInitialize : function(){
            $(owlArticle).randomize();
        }
    });


    /*
    $(function () {
        $("input,select,textarea").not("[type=submit]").jqBootstrapValidation();
    } );
    */
});

function goEntry(){
    var cookie_agreement = "agreement";
	var domain = document.domain.match(/[a-zA-Z0-9\-\_]+\.[a-zA-Z]{2,4}$/);
	if(!domain) domain = document.domain;
	document.cookie = cookie_agreement+"=true;;path=/;domain=."+domain;
	$('.cookies').removeClass('active');
}
